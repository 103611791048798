import { createAction, createAsyncAction } from "typesafe-actions";
import { IJob, IJobListTimestampRange, IJobRequest, IJobs, IOrgJobListRequest, ICreateJobRequest, IUpdateJobRequest, IUpdateRpiJobStatusRequest, IUserJobListRequest, IQueueJobRequest, IUpdateJobResultRequest } from "../../models";

export const setJobRequest = createAction('SET_JOB_REQUEST')<IJobRequest>();
export const setJobId = createAction('SET_JOB_ID')<string>();
export const updateRpiJobStatus = createAction('UPDATE_RPI_JOB_STATUS')<IUpdateRpiJobStatusRequest>();
export const setWaitingForScan = createAction('SET_WAITING_FOR_SCAN')<boolean>();

export const setUserJobsTimestampRange = createAction('SET_USER_JOBS_TIMESTAMP_RANGE')<IJobListTimestampRange>();
export const setOrgJobsTimestampRange = createAction('SET_ORG_JOBS_TIMESTAMP_RANGE')<IJobListTimestampRange>();

export const reset = createAction('RESET')<void>();

export const createJob = createAsyncAction(
    "job/CREATE_JOB_REQUEST",
    "job/CREATE_JOB_SUCCESS",
    "job/CREATE_JOB_FAILURE"
)<ICreateJobRequest, Boolean | null, Error>();

// retrials or test job
export const queueJob = createAsyncAction(
    "job/QUEUE_JOB_REQUEST",
    "job/QUEUE_JOB_SUCCESS",
    "job/QUEUE_JOB_FAILURE"
)<IQueueJobRequest, Boolean | null, Error>();

export const updateJob = createAsyncAction(
    "job/UPDATE_JOB_REQUEST",
    "job/UPDATE_JOB_SUCCESS",
    "job/UPDATE_JOB_FAILURE"
)<IUpdateJobRequest, Boolean | null, Error>();

export const updateJobResult = createAsyncAction(
    "job/UPDATE_JOB_RESULT_REQUEST",
    "job/UPDATE_JOB_RESULT_SUCCESS",
    "job/UPDATE_JOB_RESULT_FAILURE"
)<IUpdateJobResultRequest, Boolean | null, Error>();

export const userJobs = createAsyncAction(
    "job/USER_JOBS_REQUEST",
    "job/USER_JOBS_SUCCESS",
    "job/USER_JOBS_FAILURE"
)<IUserJobListRequest, IJobs | null, Error>();

export const orgJobs = createAsyncAction(
    "job/ORG_JOBS_REQUEST",
    "job/ORG_JOBS_SUCCESS",
    "job/ORG_JOBS_FAILURE"
)<IOrgJobListRequest, IJobs | null, Error>();

export const jobStatus = createAsyncAction(
    "job/JOB_STATUS_REQUEST",
    "job/JOB_STATUS_SUCCESS",
    "job/JOB_STATUS_FAILURE"
)<string, IJob | null, Error>();

export const pollJobStatus = createAsyncAction(
    "job/POLL_JOB_STATUS_REQUEST",
    "job/POLL_JOB_STATUS_SUCCESS",
    "job/POLL_JOB_STATUS_FAILURE"
)<string, IJob | null, Error>();

export const pollUserJobs = createAsyncAction(
    "job/POLL_USER_JOBS_REQUEST",
    "job/POLL_USER_JOBS_SUCCESS",
    "job/POLL_USER_JOBS_FAILURE"
)<IUserJobListRequest, IJobs | null, Error>();

export const pollOrgJobs = createAsyncAction(
    "job/POLL_ORG_JOBS_REQUEST",
    "job/POLL_ORG_JOBS_SUCCESS",
    "job/POLL_ORG_JOBS_FAILURE"
)<IOrgJobListRequest, IJobs | null, Error>();

export const deleteJob = createAsyncAction(
    "job/DELETE_JOB_REQUEST",
    "job/DELETE_JOB_SUCCESS",
    "job/DELETE_JOB_FAILURE"
)<string, Boolean | null, Error>();
import { createAction, createAsyncAction } from "typesafe-actions";
import { 
  IUploadJobImageRequest, 
  IRejectionMapping,
  IImageWithLabel,
  FetchDatasetDetailsRequest,
  UpdateImageLabelsRequest,
  UpdateImageLabelsSuccess,
  UpdateImageLabelsFailure,
  onSubmitLabelingRequest,
  onSubmitLabelingFailure,
  DatasetInfo,
  FetchDatasetsByGrainIdMeta
} from "../../models/UploadImages";
import { FetchDatasetInfoRequest, FetchDatasetInfoResponse } from "src/models/Training";
import { LabelingStatus } from "../../models/LabelingStatus";

export const reset = createAction('RESET')<void>();


export const uploadML = createAsyncAction(
  "PRESIGNED_URL_REQUEST",
  "PRESIGNED_URL_SUCCESS",
  "PRESIGNED_URL_FAILURE"
)<IUploadJobImageRequest, string, Error>();

export const clearJobDetails = createAction('CLEAR_JOB_DETAILS')();
export const setDatasetId = createAction('SET_DATASET_ID')<string>();
export const setRejectionMapping = createAction('SET_REJECTION_MAPPING')<IRejectionMapping>();
export const setImages = createAction('SET_IMAGES')<IImageWithLabel[]>();
export const setUploadProgress = createAction('SET_UPLOAD_PROGRESS')<number>();
export const uploadComplete = createAction('UPLOAD_COMPLETE')<boolean>();
export const setIsJob = createAction('SET_IS_JOB')<boolean>();
export const setId = createAction('SET_ID')<string>();
export const clearDataset = createAction('CLEAR_DATASET')<void>();
export const initializeLabeling = createAsyncAction(
    "INITIALIZE_LABELING_REQUEST",
    "INITIALIZE_LABELING_SUCCESS",
    "INITIALIZE_LABELING_FAILURE"
  )<{ grainId: string }, void, Error>();

export const getJobDetails = createAsyncAction(
  "GET_JOB_DETAILS_REQUEST",
  "GET_JOB_DETAILS_SUCCESS",
  "GET_JOB_DETAILS_FAILURE"
)<{datasetId: any}, any, Error>();


  export const setIsExistingDataset = createAction('SET_IS_EXISTING_DATASET')<boolean>();
  export const fetchDatasetInfo = createAsyncAction(
    "FETCH_DATASET_INFO_REQUEST",
    "FETCH_DATASET_INFO_SUCCESS",
    "FETCH_DATASET_INFO_FAILURE"
  )<FetchDatasetInfoRequest, FetchDatasetInfoResponse, Error>();

export const fetchDatasetDetails = createAsyncAction(
  'FETCH_DATASET_DETAILS_REQUEST',
  'FETCH_DATASET_DETAILS_SUCCESS',
  'FETCH_DATASET_DETAILS_FAILURE'
)<FetchDatasetDetailsRequest, any, Error>();

export const updateImageLabels = createAsyncAction(
  'UPDATE_IMAGE_LABELS_REQUEST',
  'UPDATE_IMAGE_LABELS_SUCCESS',
  'UPDATE_IMAGE_LABELS_FAILURE'
)<UpdateImageLabelsRequest,UpdateImageLabelsSuccess,UpdateImageLabelsFailure>();

export const updateLabelingStatus = createAction('UPDATE_LABELING_STATUS')<LabelingStatus>();


export const saveAsDraft = createAsyncAction(
  "SAVE_AS_DRAFT_REQUEST",
  "SAVE_AS_DRAFT_SUCCESS",
  "SAVE_AS_DRAFT_FAILURE"
)<onSubmitLabelingRequest,void,onSubmitLabelingFailure>();

export const completeLabeling = createAsyncAction(
  "COMPLETE_LABELING_REQUEST",
  "COMPLETE_LABELING_SUCCESS",
  "COMPLETE_LABELING_FAILURE"
)<onSubmitLabelingRequest,void,onSubmitLabelingFailure>();

export const fetchRejectionMapping = createAsyncAction(
  "FETCH_REJECTION_MAPPING_REQUEST",
  "FETCH_REJECTION_MAPPING_SUCCESS",
  "FETCH_REJECTION_MAPPING_FAILURE"
)<{ grainId: string }, IRejectionMapping, Error>();

export const fetchDatasetsByGrainId = createAsyncAction(
  "FETCH_DATASETS_BY_GRAIN_ID_REQUEST",
  "FETCH_DATASETS_BY_GRAIN_ID_SUCCESS",
  "FETCH_DATASETS_BY_GRAIN_ID_FAILURE"
)<string, DatasetInfo[], string, FetchDatasetsByGrainIdMeta>();

export const fetchLabelCounts = createAsyncAction(
  "FETCH_LABEL_COUNTS_REQUEST",
  "FETCH_LABEL_COUNTS_SUCCESS", 
  "FETCH_LABEL_COUNTS_FAILURE"
)<{datasetId: string}, {datasetId: string, counts: Record<string, number>}, Error>();

export const deleteDataset = createAsyncAction(
  "DELETE_DATASET_REQUEST",
  "DELETE_DATASET_SUCCESS", 
  "DELETE_DATASET_FAILURE"
)<string, string, Error>();
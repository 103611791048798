import { ActionType, getType } from "typesafe-actions";
import * as TrainingActions from '../actions/trainingAction';
import { TrainingJobInfo } from 'src/models/Training';
import { TestingJobInfo } from "src/models/testingJob";

interface TrainingState {
  trainingJobs: TrainingJobInfo[];
  currentTrainingJob: TrainingJobInfo | null;
  loading: boolean;
  error: string | null;
  testingJobs: TestingJobInfo[];
}

const initialState: TrainingState = {
  trainingJobs: [],
  currentTrainingJob: null,
  loading: false,
  testingJobs: [],
  error: null,
  
};

export const trainingReducer = (
  state: TrainingState = initialState,
  action: ActionType<typeof TrainingActions>
): TrainingState => {
  switch (action.type) {
    case getType(TrainingActions.createTrainingJob.request):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getType(TrainingActions.createTrainingJob.success):
      return {
        ...state,
        loading: false,
        trainingJobs: [...state.trainingJobs, action.payload],
        currentTrainingJob: action.payload,
      };
    case getType(TrainingActions.createTrainingJob.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case getType(TrainingActions.fetchTrainingJobDetails.request):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getType(TrainingActions.fetchTrainingJobDetails.success):
      return {
        ...state,
        loading: false,
        currentTrainingJob: action.payload,
      };
    case getType(TrainingActions.fetchTrainingJobDetails.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case getType(TrainingActions.fetchAllTrainingJobs.request):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getType(TrainingActions.fetchAllTrainingJobs.success):
      return {
        ...state,
        loading: false,
        trainingJobs: action.payload,
      };
    case getType(TrainingActions.fetchAllTrainingJobs.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case getType(TrainingActions.updateTrainingJob.request):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getType(TrainingActions.updateTrainingJob.success): {
      const updatedJobs = state.trainingJobs.map((job) =>
        job.trainingJobID === action.payload.trainingJobID ? action.payload : job
      );
    
      const isCurrentJobUpdated =
        state.currentTrainingJob?.trainingJobID === action.payload.trainingJobID;
    
      return {
        ...state,
        loading: false,
        trainingJobs: updatedJobs,
        currentTrainingJob: isCurrentJobUpdated
          ? { ...state.currentTrainingJob, ...action.payload }
          : state.currentTrainingJob,
      };
    }      
    case getType(TrainingActions.updateTrainingJob.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case getType(TrainingActions.clearTrainingError):
      return {
        ...state,
        error: null,
      };
    case getType(TrainingActions.reset):
      return initialState;
    default:
      return state;
  }
};

import { createAction, createAsyncAction } from "typesafe-actions";
import { CreateTrainingJobRequest, TrainingJobInfo } from 'src/models/Training';
import { CreateTestingJobRequest, TestingJobInfo } from "src/models/testingJob";

export const reset = createAction('RESET')<void>();
export const createTrainingJob = createAsyncAction(
    'CREATE_TRAINING_JOB_REQUEST',
    'CREATE_TRAINING_JOB_SUCCESS',
    'CREATE_TRAINING_JOB_FAILURE',
)<CreateTrainingJobRequest, TrainingJobInfo, string>();

export const fetchTrainingJobDetails = createAsyncAction(
    'FETCH_TRAINING_JOB_DETAILS_REQUEST',
    'FETCH_TRAINING_JOB_DETAILS_SUCCESS',
    'FETCH_TRAINING_JOB_DETAILS_FAILURE',
    )<string, TrainingJobInfo, string>();
    
export const fetchAllTrainingJobs = createAsyncAction(
    'FETCH_ALL_TRAINING_JOBS_REQUEST',
    'FETCH_ALL_TRAINING_JOBS_SUCCESS',
    'FETCH_ALL_TRAINING_JOBS_FAILURE',
    )<void, TrainingJobInfo[], string>();

export const updateTrainingJob = createAsyncAction(
    'UPDATE_TRAINING_JOB_REQUEST',
    'UPDATE_TRAINING_JOB_SUCCESS',
    'UPDATE_TRAINING_JOB_FAILURE',
    )<any, TrainingJobInfo, string>();
 
export const resetTestingJobs = createAction('RESET_TESTING_JOBS')<void>();

export const createTestingJob = createAsyncAction(
    'CREATE_TESTING_JOB_REQUEST',
    'CREATE_TESTING_JOB_SUCCESS',
    'CREATE_TESTING_JOB_FAILURE',
)<CreateTestingJobRequest, TestingJobInfo, string>();

export const fetchTestingJobDetails = createAsyncAction(
    'FETCH_TESTING_JOB_DETAILS_REQUEST',
    'FETCH_TESTING_JOB_DETAILS_SUCCESS',
    'FETCH_TESTING_JOB_DETAILS_FAILURE',
)<string, TestingJobInfo, string>();

export const clearTrainingError = createAction('CLEAR_TRAINING_ERROR')<void>();

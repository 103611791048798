import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as UploadAction from "../actions/dataset";
import { 
  initiateMultipartUpload, uploadParts, completeMultipartUpload 
} from "../../api/mlUpload";
import {
  createDataset,
  fetchDatasetInfo as fetchDatasetIdsApi,
  fetchDatasetDetails as fetchDatasetDetailsApi,
  updateImageLabelsApi,
  fetchAllImagesFromS3,
  getDatasetsByGrainId ,
  deleteDatasetApi,
  fetchLabelCounts as fetchLabelCountsApi
} from "../../api/ml-dataset";
import { getRejectionMapping } from "../../api/grain";
import { showErrorMessage } from "./shared";
import { IRejectionMapping, Image, CreateDatasetRequest } from "src/models/UploadImages";
import { LabelingStatus } from "src/models/LabelingStatus";
import {FetchDatasetInfoResponse } from "src/models/Training";

// Selectors
import { IStore } from "../reducers";
import { IImageWithLabel, IUploadJobImageRequest } from "src/models/UploadImages";

const CONCURRENT_UPLOADS = 10;
const MAX_DYNAMODB_ITEM_SIZE = 400 * 1024;

function* fetchRejectionMapping(action: ReturnType<typeof UploadAction.fetchRejectionMapping.request>) {
  try {
    const rejectionMappingFromApi: IRejectionMapping = yield call(getRejectionMapping, action.payload.grainId);
    const rejectionMapping: IRejectionMapping = { good: "Good Grains", na: "NA",Broken:"Broken", ...rejectionMappingFromApi };
    yield put(UploadAction.fetchRejectionMapping.success(rejectionMapping));
  } catch (error:any) {
    yield put(UploadAction.fetchRejectionMapping.failure(error));
  }
}
function* getRejectionMappingWithDefaults(grainId: string): Generator<any, IRejectionMapping, any> {
  const rejectionMappingFromApi: IRejectionMapping = yield call(getRejectionMapping, grainId);
  const rejectionMapping: IRejectionMapping = { good: "Good Grains", na: "NA",Broken:"Broken", ...rejectionMappingFromApi };
  return rejectionMapping;
}

function* fetchDatasetInfoSaga(action: ReturnType<typeof UploadAction.fetchDatasetInfo.request>): Generator<any, void, any> {
  try {
    const { limit = 50, lastKey = null } = action.payload;
    const grainIdFilter: string = yield select((state) => state.app.grainIdFilter);

    if (grainIdFilter && grainIdFilter !== 'all') {
      const rejectionMapping = yield call(getRejectionMappingWithDefaults, grainIdFilter);
      yield put(UploadAction.setRejectionMapping(rejectionMapping));
    } else {
      yield put(UploadAction.setRejectionMapping({}));
    }

    const response: FetchDatasetInfoResponse = yield call(fetchDatasetIdsApi, limit, lastKey);
    yield put(UploadAction.fetchDatasetInfo.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.fetchDatasetInfo.failure(error));
  }
}


function parseCSV(csvData: string): Image[] {
  const lines = csvData.split("\n").map(line => line.trim()).filter(line => line);
  if (lines.length < 2) return [];
  // Assume the first line is the header.
  const headers = lines[0].split(",").map(h => h.trim().replace(/^"|"$/g, ""));
  const result: Image[] = [];
  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",").map(v => v.trim().replace(/^"|"$/g, ""));
    const imageObj: any = {};
    headers.forEach((header, index) => {
      imageObj[header] = values[index];
    });
    result.push(imageObj);
  }
  return result;
}

function* fetchDatasetDetails(action: ReturnType<typeof UploadAction.fetchDatasetDetails.request>): Generator<any, void, any> {
  try {
    const { datasetId } = action.payload;
    if (!datasetId) {
      console.warn('fetchDatasetDetails called without datasetId.');
      return;
    }
    
    // Call the API to get dataset details.
    const datasetDetails: any = yield call(fetchDatasetDetailsApi, datasetId);
    console.log('fetchDatasetDetails:', datasetDetails);
    
    // Determine the image list source.
    let imageList: Image[] = [];
   if (datasetDetails.imageListUrl) {
      // New response: fetch and parse CSV from the signed URL.
      const csvResponse: Response = yield call(fetch, datasetDetails.imageListUrl);
      const csvText: string = yield call([csvResponse, csvResponse.text]);
      imageList = parseCSV(csvText);
    }else if (datasetDetails.imageList) {
      // Old response: imageList array is available directly.
      imageList = datasetDetails.imageList;
    }
    
    // Get additional data required for processing.
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const rejectionMapping: IRejectionMapping = yield call(getRejectionMappingWithDefaults, grainId);
    const s3ImageResponse = yield call(fetchAllImagesFromS3, datasetId);
    const s3ImageUrls: string[] = s3ImageResponse.imageUrls;
    
    // Combine the image list with the signed S3 image URLs.
    const combinedImages: IImageWithLabel[] = imageList.map(img => {
      const matchedUrl = s3ImageUrls.find(url => {
        const decodedUrl = decodeURIComponent(url);
        const urlPath = decodedUrl.split("?")[0];
        return urlPath.includes(img.imagePath);
      });
      
      return {
        ...img,
        imageUrl: matchedUrl ? matchedUrl : null,
      };
    });
    
    yield put(
      UploadAction.fetchDatasetDetails.success({
        ...datasetDetails,
        // Optionally include the parsed imageList for further use.
        imageList,
        combinedImages,
        rejectionMapping,
      })
    );
  } catch (error: any) {
    yield put(UploadAction.fetchDatasetDetails.failure(error));
  }
}

function* getJobDetails(action: ReturnType<typeof UploadAction.getJobDetails.request>): Generator<any, void, any> {
  try {
    const { datasetId } = action.payload;
    const jobDetails = yield call(fetchDatasetDetailsApi, datasetId);
    yield put(UploadAction.getJobDetails.success(jobDetails));
  } catch (error:any) {
    yield put(UploadAction.getJobDetails.failure(error));
  }
} 


function* updateImageLabels(action: ReturnType<typeof UploadAction.updateImageLabels.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    yield call(updateImageLabelsApi, datasetId, changedLabels, status, grainId);
    yield put(UploadAction.updateImageLabels.success({ changedLabels, status, grainId }));
  } catch (error: any) {
    yield put(UploadAction.updateImageLabels.failure(error));
  }
}

export function* saveAsDraft(action: ReturnType<typeof UploadAction.saveAsDraft.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    if (!datasetId.includes('job')) {
      yield call(createDatasetIfNotExists, datasetId, grainId, changedLabels);
    }
    yield call(updateImageLabels, { payload: { datasetId, changedLabels, status, grainId } });
    yield put(UploadAction.saveAsDraft.success());
    yield put(UploadAction.updateLabelingStatus(LabelingStatus.IN_PROGRESS));
  } catch (error : any){
    yield put(UploadAction.saveAsDraft.failure(error));
  }
}
function* fetchDatasetsByGrainIdSaga(action: { payload: string }): Generator<any, void, any> {
  try {
    const grainId = action.payload;
    const response = yield call(getDatasetsByGrainId, grainId);
    yield put(UploadAction.fetchDatasetsByGrainId.success(response.datasets));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.fetchDatasetsByGrainId.failure(error.message));
  }
}

export function* completeLabeling(action: ReturnType<typeof UploadAction.completeLabeling.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    if (!datasetId.includes('job')) {
      yield call(createDatasetIfNotExists, datasetId, grainId, changedLabels);
    }
    yield call(updateImageLabels, { payload: { datasetId, changedLabels, status, grainId } });
    yield put(UploadAction.completeLabeling.success());
    yield put(UploadAction.updateLabelingStatus(LabelingStatus.COMPLETED));
  } catch (error: any) {
    yield put(UploadAction.completeLabeling.failure(error));
  }
}
export function* createDatasetIfNotExists(datasetId: string, grainId: string, imageList: { [key: string]: string }): Generator<any, void, any> {
  try {
    if (!datasetId.includes('job')) {
      const imageArray = Object.entries(imageList).map(([imagePath, label]) => ({
        imagePath,
        label,
      }));

      const request: CreateDatasetRequest = {
        datasetID: datasetId,
        imageList: imageArray,
        grainId,
        status: 'IN_PROGRESS',
        username: yield select((state: IStore) => state.user?.userProfile?.response?.username ?? ''),
      };

      const response = yield call(createDataset, request);
      yield put(UploadAction.setDatasetId(datasetId));
    }
  } catch (error) {
    console.error('Error creating dataset:', error);
    throw error;
  }
}

async function urlToFile(url: string, filename: string): Promise<File> {
  // Append a timestamp to avoid caching
  const timestamp = new Date().getTime();
  const urlWithTimestamp = `${url}?timestamp=${timestamp}`;
  
  const response = await fetch(urlWithTimestamp);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
}


function* uploadExistingFile(file: File, datasetId: string, selectedLabel: string, totalFiles: number,progressCounter: { count: number }, uploadedImages: IImageWithLabel[]): Generator<any, void, any> {
  const grainId: string = yield select((state: IStore) => state.grainType.grainId);
  const uploadPath = `ml-images/${grainId}/${datasetId}`;
  const key = `${uploadPath}/${file.name}`;
  
  try {
    const { uploadId, presignedUrls } = yield call(initiateMultipartUpload, key, file.type);
    const parts = yield call(uploadParts, presignedUrls, file);
    yield call(completeMultipartUpload, key, uploadId, parts);

    progressCounter.count++;
    const newProgress = Math.round((progressCounter.count / totalFiles) * 100);
    yield put(UploadAction.setUploadProgress(newProgress));

    uploadedImages.push({
      imagePath: key,
      label: selectedLabel,
    });
  } catch (error) {
    console.error(`Error uploading file ${file.name}:`, error);
  }
}

function* uploadNewFile(
  file: File | string,
  label: string,
  datasetId: string,
  totalFiles: number,
  progressCounter: { count: number },
  uploadedImages: IImageWithLabel[]
): Generator<any, void, any> {
  const grainId: string = yield select((state: IStore) => state.grainType.grainId);
  
  // Determine if file is a URL string and convert it to a File if needed
  let fileObj: File;
  if (typeof file === "string") {
    // Extract filename from the URL (e.g., everything after the last slash)
    const urlParts = file.split('/');
    const filename = urlParts[urlParts.length - 1];
    fileObj = yield call(urlToFile, file, filename);
  } else {
    fileObj = file;
  }
  
  const fileName = fileObj.name;
  const uploadPath = `ml-images/${grainId}/${datasetId}`;
  const key = `${uploadPath}/${fileName}`;

  try {
    console.log("Uploading file:", fileObj);
    const { uploadId, presignedUrls } = yield call(initiateMultipartUpload, key, fileObj.type);
    const parts = yield call(uploadParts, presignedUrls, fileObj);
    yield call(completeMultipartUpload, key, uploadId, parts);

    progressCounter.count++;
    const newProgress = Math.round((progressCounter.count / totalFiles) * 100);
    yield put(UploadAction.setUploadProgress(newProgress));

    uploadedImages.push({ imagePath: key, label });
  } catch (error) {
    console.error(`Error uploading ${fileName}:`, error);
  }
}

export function* uploadConcurrently(action: ReturnType<typeof UploadAction.uploadML.request>): Generator<any, void, any> {
  const { files, datasetId, selectedLabel }: IUploadJobImageRequest = action.payload;
  const isExistingDataset: boolean = yield select((state: IStore) => state.dataset.isExistingDataset);
  const totalFiles = files.length;
  const uploadedImages: IImageWithLabel[] = [];
  const progressCounter = { count: 0 };

  yield put(UploadAction.setUploadProgress(0));

  if (isExistingDataset) {
    const chunks = [];
    for (let i = 0; i < totalFiles; i += CONCURRENT_UPLOADS) {
      chunks.push(files.slice(i, i + CONCURRENT_UPLOADS));
    }
    let currentIndex = 0;
    for (const chunk of chunks) {
      yield all(
        chunk.map((file, index) =>
          call(
            uploadExistingFile,
            file,
            datasetId,
            Array.isArray(selectedLabel) ? selectedLabel[currentIndex + index] : selectedLabel,
            totalFiles,
            progressCounter,
            uploadedImages
          )
        )
      );
      currentIndex += chunk.length;
    }
    if (uploadedImages.length > 0) {
      const changedLabels = uploadedImages.reduce((acc, image) => ({
        ...acc,
        [image.imagePath]: image.label,
      }), {} as Record<string, string>);

      const grainId: string = yield select((state: IStore) => state.grainType.grainId);
      yield call(
        updateImageLabelsApi,
        datasetId,
        changedLabels,
        'IN_PROGRESS',
        grainId
      );
    }

    yield put(UploadAction.setUploadProgress(100));
    yield put(UploadAction.uploadComplete(true));
    yield put(UploadAction.uploadML.success("Upload successful"));
  } else {
    console.log("Uploading new files:", files);
    console.log("label", selectedLabel);
    // Create image objects with appropriate label assignment:
    const imageObjects = files.map((file, index) => {
      const labelValue = Array.isArray(selectedLabel) ? selectedLabel[index] : selectedLabel;
      return {
        path: file.name,
        label: labelValue,
        file,
      };
    });

    const chunks = [];
    for (let i = 0; i < imageObjects.length; i += CONCURRENT_UPLOADS) {
      chunks.push(imageObjects.slice(i, i + CONCURRENT_UPLOADS));
    }
    
    for (const chunk of chunks) {
      yield all(
        chunk.map(({ file, label }) =>
          call(uploadNewFile, file, label, datasetId, totalFiles, progressCounter, uploadedImages)
        )
      );
    }
    console.log("at 361",datasetId)
    if (uploadedImages.length > 0) {
      yield call(uploadComplete, uploadedImages);
      console.log("called")
      yield put(UploadAction.uploadML.success("Upload successful"));
    } else {
      throw new Error("No images were successfully uploaded");
    }
  }
}

function calculateSizeOfRequest(imageList: IImageWithLabel[]): number {
  return new Blob([JSON.stringify(imageList)]).size;
}


function* createDatasetInChunks(images: IImageWithLabel[], datasetId: string, grainId: string, username: string): Generator<any, void, any> {
  let currentChunk: IImageWithLabel[] = [];
  let currentSize = 0;
  let chunkIndex = 0;

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    // Calculate the size of the current chunk plus the new image
    const newChunk = [...currentChunk, image];
    const newSize = calculateSizeOfRequest(newChunk);

    if (newSize > MAX_DYNAMODB_ITEM_SIZE) {
      // If adding the image exceeds the limit, save the current chunk and start a new one
      if (currentChunk.length > 0) {
        yield call(createDatasetChunk, currentChunk, datasetId, grainId, username, chunkIndex, false);
        chunkIndex++;
        currentChunk = [];
        currentSize = 0;
      }
      // Now add the image to the new chunk (it might be larger than MAX, but we proceed anyway)
      currentChunk.push(image);
      currentSize = calculateSizeOfRequest(currentChunk);
    } else {
      currentChunk.push(image);
      currentSize = newSize;
    }
  }

  // Save the remaining chunk
  if (currentChunk.length > 0) {
    yield call(createDatasetChunk, currentChunk, datasetId, grainId, username, chunkIndex, true);
  }
}

function* createDatasetChunk(
  imageChunk: IImageWithLabel[],
  datasetId: string,
  grainId: string,
  username: string,
  chunkIndex: number,
  isLastChunk: boolean
) {
  const request = {
    datasetID: datasetId,
    imageList: imageChunk,
    grainId,
    status: isLastChunk ? "NOT_LABELED" : "PARTIAL",
    username,
    chunkIndex, // Track the chunk order
    totalChunks: isLastChunk ? chunkIndex + 1 : undefined, // Set total on last chunk
  };

  try {
    const response = yield call(createDataset, request);
    console.log(`Created dataset chunk ${chunkIndex}:`, response);

  } catch (error) {
    console.error(`Error creating dataset chunk ${chunkIndex}:`, error);
    throw error;
  }
}
export function* uploadComplete(uploadedImages: IImageWithLabel[]): Generator<any, void, any> {
  try {
    let datasetId: string = yield select((state: IStore) => state.dataset.datasetID);
    const id: string = yield select((state: IStore) => state.dataset.id);
    const isJob:Boolean  =yield select((state: IStore) => state.dataset.id);
    datasetId = isJob?id:datasetId
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const username: string = yield select((state: IStore) => state.user?.userProfile.response?.username ?? '');
    console.log("datasetid",datasetId)
    if (!uploadedImages || !Array.isArray(uploadedImages) || uploadedImages.length === 0) {
      throw new Error('Images array is empty or invalid.');
    }

    yield call(createDatasetInChunks, uploadedImages, datasetId, grainId, username);
    yield put(UploadAction.setDatasetId(datasetId));
    yield put(UploadAction.setImages(uploadedImages));
  } catch (error: any) {
    console.error('Error in uploadComplete:', error);
    yield put(UploadAction.uploadML.failure(error.message));
  }
}
export function* initializeLabeling(action: ReturnType<typeof UploadAction.initializeLabeling.request>): Generator<any, void, any> {
  try {
    const datasetId = `dataset_${Date.now()}`;
    yield put(UploadAction.setDatasetId(datasetId));
    yield put(UploadAction.setUploadProgress(0));
    yield put(UploadAction.initializeLabeling.success());
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.initializeLabeling.failure(error));
  }
}
function* deleteDatasetSaga(action: ReturnType<typeof UploadAction.deleteDataset.request>) {
  try {
    const datasetId = action.payload;
    yield call(deleteDatasetApi, datasetId);
    yield put(UploadAction.deleteDataset.success(datasetId));
  } catch (error: any) {
    yield put(UploadAction.deleteDataset.failure(error));
  }
}
function* fetchLabelCountsSaga(action: ReturnType<typeof UploadAction.fetchLabelCounts.request>) { 
  try {
    const { datasetId } = action.payload;
    const counts: Record<string, number> = yield call(fetchLabelCountsApi, datasetId);
    console.log('Fetched label counts:', counts);
    const cleanedCounts = Object.entries(counts).reduce((acc, [key, value]) => {
      const cleanedKey = key.replace(/^"|"$/g, '');
      acc[cleanedKey] = value;
      return acc;
    }, {} as Record<string, number>);
    console.log('Fetched label counts:', cleanedCounts);
    yield put(UploadAction.fetchLabelCounts.success({ datasetId, counts: cleanedCounts }));
  } catch (error) {
    yield put(UploadAction.fetchLabelCounts.failure(error));
  }
}
// Watchers
export function* watchUploadDataset() {
  yield takeEvery(UploadAction.uploadML.request, uploadConcurrently);
}

export function* watchInitializeLabeling() {
  yield takeEvery(UploadAction.initializeLabeling.request, initializeLabeling);
}

export function* watchCompleteLabeling() {
  yield takeEvery(UploadAction.completeLabeling.request, completeLabeling);
}
export function* watchRejections() {
  yield takeEvery(UploadAction.fetchRejectionMapping.request, fetchRejectionMapping);
}
export function* watchFetchDatasetDetails() {
  yield takeEvery(UploadAction.fetchDatasetDetails.request, function* (action) {
    console.log('Triggered watchFetchDatasetDetails:', action);
    yield call(fetchDatasetDetails, action);
  });
}

export function* watchSaveAsDraft() {
  yield takeEvery(UploadAction.saveAsDraft.request, saveAsDraft);
}

export function* watchFetchDatasetInfo() {
  yield takeEvery(UploadAction.fetchDatasetInfo.request, fetchDatasetInfoSaga);
}

export function* watchUpdateImageLabels() {
  yield takeEvery(UploadAction.updateImageLabels.request, updateImageLabels);
}
export function* watchGetJobDetails() {
  yield takeEvery(UploadAction.getJobDetails.request, getJobDetails)
}
export function* watchDeleteDataset() {
  yield takeEvery(UploadAction.deleteDataset.request, deleteDatasetSaga);
}
function* watchFetchLabelCounts() {
  yield takeEvery(UploadAction.fetchLabelCounts.request, fetchLabelCountsSaga);
}

function* watchFetchDatasetsByGrainId() {
  yield takeEvery(UploadAction.fetchDatasetsByGrainId.request, fetchDatasetsByGrainIdSaga);
}
export default function* rootSaga() {
  yield all([
    fork(watchUploadDataset),
    fork(watchInitializeLabeling),
    fork(watchCompleteLabeling),
    fork(watchFetchDatasetDetails),
    fork(watchSaveAsDraft),
    fork(watchFetchDatasetInfo),
    fork(watchUpdateImageLabels),
    fork(watchGetJobDetails),
    fork(watchDeleteDataset),
    fork(watchRejections),,
    fork(watchFetchLabelCounts),
    fork(watchFetchDatasetsByGrainId)
  ]);
}

import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/dataset";
import {
  IUploadFileState,
  IImageWithLabel,
  IRejectionMapping,
  DatasetInfo,
} from "src/models/UploadImages";
import { LabelingStatus } from "src/models/LabelingStatus";
type DatasetAction = ActionType<typeof actions>;
export interface IDatasetState {
  readonly uploadML: IUploadFileState;
  readonly datasetID: string | null;
  readonly rejectionMapping: IRejectionMapping;
  readonly images: IImageWithLabel[];
  readonly uploadProgress: number;
  readonly datasets: {
    updatedAt: any;
    grainId: string;
    username: any;
    datasetID: string; 
    status: string,
  }[];
  readonly datasetsByGrainId: {
    [grainId: string]: {
      datasets: DatasetInfo[];
      loading: boolean;
      error?: string;
    };
  };
  readonly labelCountsMap: {
    [datasetId: string]: {
      counts: Record<string, number>;
      loading: boolean;
      error?: string | null;
    }
  };
  readonly nextKey: string | null; 
  readonly selectedDatasetId: string | null;
  readonly labelingStatus: LabelingStatus;
  readonly uploadCompleted: boolean;
  readonly updateImageLabel: { imagePath: string, label: string };
  readonly loading: boolean;
  readonly error?: any;
  readonly isExistingDataset?: boolean;
  readonly jobDetails?: any;
  readonly isJob: boolean; 
  readonly id: string;
}

export const initialDatasetState: IDatasetState = {
  uploadML: {
    error: undefined,
    isFetching: false,
    success: false,
    s3Path: undefined,
  },
  datasetID: null,
  rejectionMapping: {},
  images: [],
  uploadProgress: 0,
  datasets: [],
  datasetsByGrainId: {},
  nextKey: null,
  selectedDatasetId: null,
  labelingStatus: LabelingStatus.NOT_STARTED,
  uploadCompleted: false,
  updateImageLabel: { imagePath: '', label: '' },
  loading: false,
  isExistingDataset: false,
  jobDetails: null,
  isJob: false,
  id: '',
  labelCountsMap: {},
};

export const DatasetReducer = (
  state: IDatasetState = initialDatasetState,
  action: ActionType<typeof actions>
): IDatasetState => {
  switch (action.type) {
    case getType(actions.uploadML.request):
      return { ...state, uploadML: { ...state.uploadML, isFetching: true }, loading: true, uploadCompleted: false };

    case getType(actions.uploadML.success):
      return { ...state, uploadProgress: 100, loading: false, uploadCompleted: true };

    case getType(actions.uploadML.failure):
      return { ...state, uploadML: { ...state.uploadML, isFetching: false, error: action.payload }, loading: false };

    case getType(actions.setDatasetId):
      return { ...state, datasetID: action.payload };

    case getType(actions.setRejectionMapping):
      return { ...state, rejectionMapping: action.payload };
    
    case getType(actions.setIsExistingDataset):
      return { ...state, isExistingDataset: action.payload };

    case getType(actions.setImages):
      return { ...state, images: action.payload };

    case getType(actions.clearDataset):
      return {
          ...state,
          datasetID: null,
          images: [],
          datasets: [],
          nextKey: null,
          id: '',
          uploadCompleted: false
      };
    

    case getType(actions.updateImageLabels.request):
      return { ...state, loading: true };
    
    case getType(actions.updateImageLabels.success):
      return {
        ...state,
        images: state.images.map((img) => ({
          ...img,
          label: action.payload.changedLabels[img.imagePath] || img.label,
        })),
        labelingStatus: action.payload.status,
        loading: false,
      };

    case getType(actions.updateImageLabels.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.fetchDatasetDetails.request):
      return { ...state, loading: true };

    case getType(actions.fetchDatasetDetails.success):
      const { combinedImages, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
        images: combinedImages,
        loading: false,
        error: undefined
      };

    case getType(actions.fetchDatasetDetails.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.clearJobDetails):
      return { ...state, jobDetails: null };

    case getType(actions.uploadComplete):
      return { 
        ...state, 
        uploadCompleted: action.payload,
        loading: false 
      };

    case getType(actions.setUploadProgress):
      return { ...state, uploadProgress: action.payload };
    case getType(actions.deleteDataset.request):
      return { ...state, loading: true };
      
    case getType(actions.deleteDataset.success): {
      const deletedId = action.payload;
      return {
        ...state,
        datasets: state.datasets.filter(ds => ds.datasetID !== deletedId),
        loading: false
      };
    }
      
    case getType(actions.deleteDataset.failure):
      return { ...state, loading: false, error: action.payload };
    case getType(actions.completeLabeling.request):
      return { ...state, loading: true };

    case getType(actions.completeLabeling.success):
      return { ...state, labelingStatus: LabelingStatus.COMPLETED, loading: false };

    case getType(actions.completeLabeling.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.initializeLabeling.request):
      return { ...state, loading: true };

    case getType(actions.initializeLabeling.success):
      return { ...state, labelingStatus: LabelingStatus.NOT_STARTED, loading: false };

    case getType(actions.initializeLabeling.failure):
      return { ...state, loading: false, error: action.payload };
    case getType(actions.fetchRejectionMapping.request):
      return { ...state, loading: true };
    case getType(actions.fetchRejectionMapping.success):
      return { ...state, rejectionMapping: action.payload, loading: false };

    case getType(actions.fetchRejectionMapping.failure):
      return { ...state, error: action.payload, loading: false }; 
    case getType(actions.fetchDatasetInfo.request):
      return { ...state, loading: true };     
    case getType(actions.fetchDatasetInfo.success):
      const existingDatasetIDs = new Set(state.datasets.map(ds => ds.datasetID));
      const newUniqueDatasets = action.payload.datasets.filter(
        (newDataset) => !existingDatasetIDs.has(newDataset.datasetID)
      ).map((dataset) => ({
        updatedAt: dataset.updatedAt || null,
        grainId: dataset.grainId || '',
        username: dataset.username || '',
        datasetID: dataset.datasetID,
        status: dataset.status,
      }));
      
      return { 
        ...state, 
        datasets: [...state.datasets, ...newUniqueDatasets],
        nextKey: action.payload.nextKey,
        loading: false 
      };
    
    case getType(actions.fetchDatasetInfo.failure):
      return { ...state, error: action.payload, loading: false };
      case getType(actions.fetchDatasetsByGrainId.request):
        return {
          ...state,
          datasetsByGrainId: {
            ...state.datasetsByGrainId,
            [action.payload]: {
              datasets: [],
              loading: true,
              error: undefined,
            },
          },
        };
      
      case getType(actions.fetchDatasetsByGrainId.success):
        const grainIdSuccess = action.meta?.request.payload;
        if (!grainIdSuccess) return state;
      
        return {
          ...state,
          datasetsByGrainId: {
            ...state.datasetsByGrainId,
            [grainIdSuccess]: {
              datasets: action.payload.filter(dataset => dataset.status === 'COMPLETED'),
              loading: false,
              error: undefined,
            },
          },
        };
      case getType(actions.fetchLabelCounts.request): {
        const { datasetId } = action.payload;
        return {
          ...state,
          labelCountsMap: {
            ...state.labelCountsMap,
            [datasetId]: {
              counts: state.labelCountsMap[datasetId]?.counts || {},
              loading: true,
              error: null
            }
          }
        };
      }
  
      case getType(actions.fetchLabelCounts.success): {
        const { datasetId, counts } = action.payload;
        return {
          ...state,
          labelCountsMap: {
            ...state.labelCountsMap,
            [datasetId]: {
              counts,
              loading: false,
              error: null
            }
          }
        };
      }
  
      case getType(actions.fetchLabelCounts.failure): {
        const { datasetId } = action.meta;
        return {
          ...state,
          labelCountsMap: {
            ...state.labelCountsMap,
            [datasetId]: {
              counts: state.labelCountsMap[datasetId]?.counts || {},
              loading: false,
              error: action.payload.message
            }
          }
        };
      }    
      case getType(actions.fetchDatasetsByGrainId.failure):
        const grainIdFailure = action.meta?.request.payload;
        if (!grainIdFailure) return state;
      
        return {
          ...state,
          datasetsByGrainId: {
            ...state.datasetsByGrainId,
            [grainIdFailure]: {
              datasets: [],
              loading: false,
              error: action.payload,
            },
          },
        };      
    
    case getType(actions.setIsJob):
      return { ...state, isJob: action.payload };

    case getType(actions.setId):
      return { ...state, id: action.payload };

    case getType(actions.saveAsDraft.request):
      return { ...state, loading: true };

    case getType(actions.saveAsDraft.success):
      return { ...state, loading: false };

    case getType(actions.saveAsDraft.failure):
      return { ...state, loading: false, error: action.payload };
    
    case getType(actions.getJobDetails.request):
      return { ...state, loading: true };

    case getType(actions.getJobDetails.success):
      return { ...state, jobDetails: action.payload, loading: false };

    case getType(actions.getJobDetails.failure):
      return { ...state, error: action.payload, loading: false };

    case getType(actions.reset):
      return initialDatasetState;

    default:
      return state;
  }
};
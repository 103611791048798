import { all, call, delay, fork, put, race, take, takeEvery, select } from "redux-saga/effects";

import * as JobActions from "../actions/jobAction";
import * as AppActions from "../actions/appAction";
import * as api from "../../api/inferenceJob";
import { showErrorMessage } from "./shared";
import { ICreateJobRequest, IJob, IJobAdditionalAttributes, IJobListTimestampRange, IJobRequest, IJobs, IQueueJobRequest, JobStatus } from "../../models";

// Name of action which ends the polling
const CANCEL_STATUS_POLLING = "CancelJobStatusPolling"
const CANCEL_USER_JOBS_POLLING = "CancelUserJobsPolling"

import { IStore } from "../reducers";
import { RICE_GRAIN_ID } from "src/constants";
import {
  getAttemptsOnEmptyResults,
  getPageInterval,
  getPollingDelay,
  getRefreshInterval
} from "src/utils/remoteConfig";

export const getGrainType = (store: IStore) => store.grainType.grainId;
export const getProfileId = (store: IStore) => store.grainProfile.profileId;
export const getSelectedProfileMapping = (store: IStore) => store.grainProfile.selectedGrainProfileMapping;
export const getCurrentJobId = (state: any) => state.job.jobId;

export const getUserJobsRange = (store: IStore) => store.job.userJobsRange;
export const getOrgJobsRange = (store: IStore) => store.job.orgJobsRange;

export const getUserJobsCachedRange = (store: IStore) => store.app.userJobsCachedRange;
export const getOrgJobsCachedRange = (store: IStore) => store.app.orgJobsCachedRange;

export const getDeviceId = (store: IStore) => store.notification.deviceId;
export const getUsername = (store: IStore) => store.user.userProfile.response?.username;
export const getOrgId = (store: IStore) => store.user.userProfile.response?.org.orgId;
export const getCenterId = (store: IStore) => store.user.userProfile.response?.center?.centerId;

export function* createJob(action: ReturnType<typeof JobActions.createJob.request>): any {
  try {
    const request: ICreateJobRequest = action.payload;

    const grainId = yield select(getGrainType);
    const profileId = yield select(getProfileId);
    const deviceId = yield select(getDeviceId);

    let additionalAttributes: IJobAdditionalAttributes | undefined = undefined;

    if (`${grainId}` === RICE_GRAIN_ID) {
      const selectedProfileMapping = yield select(getSelectedProfileMapping);
      additionalAttributes = {
        rice_process: selectedProfileMapping.process,
        rice_size: selectedProfileMapping.size,
        rice_color: selectedProfileMapping.color
      }
    }

    const createJobRequest: IJobRequest = {
      jobId: request.jobId,
      grainId: grainId,
      profileId: profileId,
      object_key: request.object_key,
      deviceId: deviceId,
      additionalAttributes: additionalAttributes,
      source: request.source,
      info: {
        "location": request.location,
      },
      results: request.manualEntryInput || undefined
    }

    const response = yield call(api.createJob, createJobRequest);

    yield put(JobActions.createJob.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.createJob.failure(error));
  }
}

export function* queueJob(action: ReturnType<typeof JobActions.queueJob.request>): any {
  try {
    const request: IQueueJobRequest = action.payload;
    const response = yield call(api.queueJob, request);
    yield put(JobActions.queueJob.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.queueJob.failure(error));
  }
}

export function* updateJob(action: ReturnType<typeof JobActions.updateJob.request>): any {
  try {
    const response = yield call(api.updateJob, action.payload);
    yield put(JobActions.updateJob.success(response));
    yield put(JobActions.pollJobStatus.request(action.payload.jobId));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.updateJob.failure(error));
  }
}

export function* updateJobResult(action: ReturnType<typeof JobActions.updateJobResult.request>): any {
  try {
    const response = yield call(api.updateJobResult, action.payload);
    yield put(JobActions.updateJobResult.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.updateJobResult.failure(error));
  }
}

export function* getJob(action: ReturnType<typeof JobActions.jobStatus.request>): any {
  try {
    const response = yield call(api.getJob, action.payload);
    yield put(JobActions.jobStatus.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.jobStatus.failure(error));
  }
}

function* getPollingRange(lastRange: IJobListTimestampRange): any {

  var toTimestamp = new Date().getTime() / 1000;
  var fromTimestamp = (lastRange === undefined) ? ((new Date().getTime() / 1000) - getPageInterval()) :
    ((new Date().getTime() / 1000) - getRefreshInterval());
  return {
    'from': fromTimestamp,
    'to': toTimestamp
  }
}

function* getPaginationRange(lastRange: IJobListTimestampRange): any {

  var toTimestamp = (lastRange === undefined) ? (new Date().getTime() / 1000) : lastRange.from;
  var fromTimestamp = (lastRange === undefined) ? (new Date().getTime() / 1000) - getPageInterval() :
    (lastRange.from - getPageInterval());
  return {
    'from': fromTimestamp,
    'to': toTimestamp
  }
}

export function* getJobs(action: ReturnType<typeof JobActions.userJobs.request>): any {
  try {
    const request = action.payload
    if (request.from !== undefined && request.to !== undefined) {
      const response: IJobs = yield call(api.getJobs, request);
      yield put(JobActions.userJobs.success(response));
      yield put(AppActions.setUserJobsCachedTimestampRange({
        'from': request.from,
        'to': request.to
      }));
      return;
    }
    const lastRange: IJobListTimestampRange = yield select(getUserJobsRange);

    var range: IJobListTimestampRange = lastRange

    var attempts = 0
    while (attempts < getAttemptsOnEmptyResults()) {
      range = yield call(getPaginationRange, range)

      request['from'] = range['from']
      request['to'] = range['to']

      yield put(JobActions.setUserJobsTimestampRange({
        'from': request['from'],
        'to': request['to']
      }));

      const response: IJobs = yield call(api.getJobs, request);

      yield put(JobActions.userJobs.success(response));
      yield put(AppActions.setUserJobsCachedTimestampRange({
        'from': request['from'],
        'to': request['to']
      }));
      if (response.Items.length > 0) {
        break
      }
      attempts += 1
    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.userJobs.failure(error));
  }
}

export function* getOrgJobs(action: ReturnType<typeof JobActions.orgJobs.request>): any {
  try {
    const request = action.payload
    const lastRange: IJobListTimestampRange = yield select(getOrgJobsRange);

    var range: IJobListTimestampRange = lastRange

    var attempts = 0
    while (attempts < getAttemptsOnEmptyResults()) {
      range = yield call(getPaginationRange, range)

      request['from'] = range['from']
      request['to'] = range['to']

      yield put(JobActions.setOrgJobsTimestampRange({
        'from': request['from'],
        'to': request['to']
      }));

      const response: IJobs = yield call(api.getOrgJobs, request);

      yield put(JobActions.orgJobs.success(response));
      yield put(AppActions.setOrgJobsCachedTimestampRange({
        'from': request['from'],
        'to': request['to']
      }));
      if (response.Items.length > 0) {
        break
      }
      attempts += 1
    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.orgJobs.failure(error));
  }
}

export function* jobStatusWatchWorker(action: ReturnType<typeof JobActions.pollJobStatus.request>) {
  yield put({ type: CANCEL_STATUS_POLLING })
  // Race starts two concurrent effects. We start our polling effect 'task'. As soon
  // as the take effect 'cancelled' is triggered, the 'task' will be cancelled.
  yield race({
    //4. Start the polling worker
    task: call(jobStatusPollingWorker, action),
    //5. Start a take effect waiting for the cancel action.
    cancel: take(CANCEL_STATUS_POLLING)
  })
}

export function* jobStatusPollingWorker(action: ReturnType<typeof JobActions.pollJobStatus.request>): any {
  let currentJobId = yield select(getCurrentJobId);
  if (currentJobId !== action.payload) {
    yield put({ type: CANCEL_STATUS_POLLING })
  }
  while (true) {
    try {
      const response: IJob = yield call(api.getJob, action.payload);
      yield put(JobActions.pollJobStatus.success(response));

      if (response.jobStatus !== JobStatus.JOB_COMPLETED) {
        yield delay(getPollingDelay());
      } else {
        yield put({ type: CANCEL_STATUS_POLLING })
      }
    } catch (error: any) {
      showErrorMessage(error);
      yield delay(getPollingDelay());
    }
  }
}

export function* userJobsWatchWorker(action: ReturnType<typeof JobActions.pollUserJobs.request>) {
  yield put({ type: CANCEL_USER_JOBS_POLLING })
  // Race starts two concurrent effects. We start our polling effect 'task'. As soon
  // as the take effect 'cancelled' is triggered, the 'task' will be cancelled.
  yield race({
    //4. Start the polling worker
    task: call(userJobsPollingWorker, action),
    //5. Start a take effect waiting for the cancel action.
    cancel: take(CANCEL_USER_JOBS_POLLING)
  })
}

export function* userJobsPollingWorker(action: ReturnType<typeof JobActions.pollUserJobs.request>) {
  while (true) {
    try {
      const request = action.payload
      const cachedRange: IJobListTimestampRange = yield select(getUserJobsCachedRange);

      var range: IJobListTimestampRange = yield call(getPollingRange, cachedRange)
      request['from'] = range['from']
      request['to'] = range['to']

      const response: IJobs = yield call(api.getJobs, request);
      yield put(JobActions.pollUserJobs.success(response));
      yield put(AppActions.setUserJobsCachedTimestampRange({
        'from': request['from'],
        'to': request['to']
      }));
      yield delay(getPollingDelay());
    } catch (error: any) {
      showErrorMessage(error);
      yield delay(getPollingDelay());
    }
  }
}
export function* deleteJob(action: ReturnType<typeof JobActions.deleteJob.request>): any {
  try {
    const jobId: string = action.payload;
    const response = yield call(api.deleteJob, jobId);
    yield put(JobActions.deleteJob.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.deleteJob.failure(error));
  }
}


export function* orgJobsPollingWorker(action: ReturnType<typeof JobActions.pollOrgJobs.request>) {
  while (true) {
    try {
      const request = action.payload
      const cachedRange: IJobListTimestampRange = yield select(getUserJobsCachedRange);

      var range: IJobListTimestampRange = yield call(getPollingRange, cachedRange)

      request['from'] = range['from']
      request['to'] = range['to']

      const response: IJobs = yield call(api.getOrgJobs, request);
      yield put(JobActions.pollOrgJobs.success(response));
      yield put(AppActions.setOrgJobsCachedTimestampRange({
        'from': request['from'],
        'to': request['to']
      }));

      yield delay(getPollingDelay());
    } catch (error: any) {
      showErrorMessage(error);
      yield delay(getPollingDelay());
    }
  }
}

/*
 * WATCHERS
 */

export function* watchGetJobPolling() {
  yield takeEvery(JobActions.pollJobStatus.request, jobStatusWatchWorker);
}

export function* watchUserJobsPolling() {
  yield takeEvery(JobActions.pollUserJobs.request, userJobsWatchWorker);
}

export function* watchOrgJobsPolling() {
  yield takeEvery(JobActions.pollOrgJobs.request, orgJobsPollingWorker);
}

export function* watchCreateJob() {
  yield takeEvery(JobActions.createJob.request, createJob);
}
export function* watchQueueJob() {
  yield takeEvery(JobActions.queueJob.request, queueJob);
}

export function* watchUpdateJob() {
  yield takeEvery(JobActions.updateJob.request, updateJob);
}

export function* watchUpdateJobResult() {
  yield takeEvery(JobActions.updateJobResult.request, updateJobResult);
}

export function* watchGetJob() {
  yield takeEvery(JobActions.jobStatus.request, getJob);
}

export function* watchGetUserJobs() {
  yield takeEvery(JobActions.userJobs.request, getJobs);
}

export function* watchGetOrgJobs() {
  yield takeEvery(JobActions.orgJobs.request, getOrgJobs);
}
export function* watchDeleteJob() {
  yield takeEvery(JobActions.deleteJob.request, deleteJob);
}

export default function* root() {
  yield all([fork(watchCreateJob),
  fork(watchQueueJob),
  fork(watchUpdateJob),
  fork(watchUpdateJobResult),
  fork(watchGetJob),
  fork(watchGetJobPolling),
  fork(watchGetUserJobs),
  fork(watchUserJobsPolling),
  fork(watchGetOrgJobs),
  fork(watchDeleteJob),
  fork(watchOrgJobsPolling)]);
}